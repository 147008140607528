<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message>

                <Fieldset legend="Tambah Baru" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="username">Username</label>
                                <InputText id="username" v-model="forms.username" required="true" autofocus :class="{ 'p-invalid': errorAdd.username }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.username" >{{ errorAdd.username[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="name">Nama</label>
                                <InputText id="name" v-model="forms.name" required="true" autofocus :class="{ 'p-invalid': errorAdd.name }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.name" >{{ errorAdd.name[0] }}</small>
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="level_id">Level</label>
                                <Dropdown id="level_id" v-model="forms.level_id" :loading="loadingDropdownLevelAdd" :options="dataDropdownLevelAdd" :class="{ 'p-invalid': errorAdd.level_id }" optionLabel="level_name" optionValue="level_id" placeholder="Pilih Level" :filter="true" :showClear="true" @filter="searchDropdownLevel($event ,'add')"/>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.level_id" >{{ errorAdd.level_id[0] }}</small>
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownMedrep">Medical Representative</label>
                                <Dropdown v-model="forms.id_mr" :loading="loadingDropdownMedrepAdd" :options="dataDropdownMedrepAdd" optionLabel="label" optionValue="id_mr" placeholder="Pilih Medical Representative" :filter="true" :showClear="true" @filter="searchDropdownMedrep($event, 'add')"/>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.id_mr" >{{ errorAdd.id_mr[0] }}</small>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loadingAddNew" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
                </Fieldset>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownLevel">Level</label>
                                <Dropdown v-model="filters.level_id" :loading="loadingDropdownLevelFilter" :options="dataDropdownLevelFilter" optionLabel="level_name" optionValue="level_id" placeholder="Pilih Level" :filter="true" :showClear="true" @filter="searchDropdownLevel($event, 'filter')"/>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            {{slotProps.data.username}}
                        </template>
                    </Column>
                    <Column field="name" header="Nama">
                        <template #body="slotProps">
                            {{slotProps.data.name}}
                        </template>
                    </Column>
                    <Column field="level_name" header="Level">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.level !== null"> 
                                {{slotProps.data.level.level_name}}
                            </div>
                        </template>
                    </Column>
                    <Column field="kode_mr" header="Kode Medical Representative">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.medrep !== null"> 
                                {{slotProps.data.medrep.kode_mr}}
                            </div>
                        </template>
                    </Column>
                    <Column field="name_mr" header="Nama Medical Representative">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.medrep !== null"> 
                                {{slotProps.data.medrep.name_mr}}
                            </div>
                        </template>
                    </Column>
                    <Column field="status" header="Status">
                        <template #body="slotProps">
                            <Button icon="pi pi-check" class="p-button-rounded" v-if="slotProps.data.status == 'y'"/>
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger" v-else/>
                        </template>
                    </Column>
					<Column header="Aksi" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)" v-if="permission.includes('update')"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

				<Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
					<div class="field">
						<label for="Username">Username</label>
                        <InputText id="username" v-model="item.username" required="true" autofocus :class="{ 'p-invalid': errorEdit.username }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.username" >{{ errorEdit.username[0] }}</small >
					</div>
					<div class="field">
						<label for="nama">Nama</label>
                        <InputText id="nama" v-model="item.name" required="true" autofocus :class="{ 'p-invalid': errorEdit.name }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.name" >{{ errorEdit.name[0] }}</small >
					</div>
					<div class="field">
                        <label for="level_id">Level</label>
                        <Dropdown id="level_id" ref="dlevel" v-model="item.level_id" :loading="loadingDropdownLevelEdit" :options="dataDropdownLevelEdit" :class="{ 'p-invalid': errorEdit.level_id }" optionLabel="level_name" optionValue="level_id" placeholder="Pilih Level" :filter="true" :showClear="true" @filter="searchDropdownLevel($event,'edit')"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.level_id" >{{ errorEdit.level_id[0] }}</small>
					</div>
                    <div class="field">
                        <label for="dropdownEditMedrep">Medical Representative</label>
                        <Dropdown ref="dmdrp" v-model="item.id_mr" :loading="loadingDropdownMedrepEdit" :options="dataDropdownMedrepEdit" optionLabel="label" optionValue="id_mr" placeholder="Pilih Medical Representative" :filter="true" :showClear="true" @filter="searchDropdownMedrep($event, 'edit')"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.id_mr" >{{ errorEdit.id_mr[0] }}</small>
                    </div>
					<div class="field">
						<label for="password">Password</label>
                        <Password id="password" :feedback="false" v-model="password" placeholder="Password" :toggleMask="true" :class="{'p-invalid': errorEdit.password}"></Password>
                        <small>Silahkan isi jika ingin mengganti password</small>
                        <br>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.password" >{{ errorEdit.password[0] }}</small >
					</div>
                    <div class="field">
                        <label>Status</label>
                        <Dropdown v-model="item.status" :options="dataDropdownStatusEdit" optionLabel="name" optionValue="code" placeholder="Pilih Status" :class="{ 'p-invalid': errorEdit.status }"/>
                            <small class="p-invalid" style="color: red" v-if="errorEdit.status" >{{ errorEdit.status[0] }}</small>
                    </div>
					<template #footer>
						<Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loadingEdit"/>
					</template>
				</Dialog>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingAddNew: false,
            loadingEdit: false,
            loadingDropdownLevelAdd: false,
            loadingDropdownLevelEdit: false,
            loadingDropdownLevelFilter: false,
            loadingDropdownMedrepAdd: false,
            loadingDropdownMedrepEdit: false,
            loadingExcel: false,
            loadingCsv: false,

            // dataDropdown
            dataDropdownLevelAdd: null,
            dataDropdownLevelEdit: null,
            dataDropdownLevelFilter: null,
            dataDropdownMedrepAdd: null,
            dataDropdownMedrepEdit: null,
            dataDropdownStatusEdit: [
                {name: 'ACTIVE', code: 'y'},
                {name: 'NON ACTIVE', code: 'n'},
            ],


            // addNew
            forms: {
                username: null,
                name: null,
                level_id: null,
                id_mr: null,
            },

            // edit
            item: {},
            editItemDialog: false,
            password: null,
            
            // filter
            filters: {
                level_id: null,
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
        this.searchDropdownLevel('');
        this.searchDropdownMedrep('');
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DROPDOWN
        searchDropdownLevel(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dlevel.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownLevelFilter = true;
                }else if(purpose == "add"){
                    this.loadingDropdownLevelAdd = true;
                }else if(purpose == "edit"){
                    this.loadingDropdownLevelEdit = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/level-user-hp',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownLevelFilter = res.data.data;
                        this.loadingDropdownLevelFilter = false;
                    }else if(purpose == "add"){
                        this.dataDropdownLevelAdd = res.data.data;
                        this.loadingDropdownLevelAdd = false;
                    }else if(purpose == "edit"){
                        this.dataDropdownLevelEdit = res.data.data;
                        this.loadingDropdownLevelEdit = false;
                    }else if(purpose == null){
                        this.dataDropdownLevelFilter = res.data.data;
                        this.dataDropdownLevelAdd = res.data.data;
                        this.dataDropdownLevelEdit = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownMedrep(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dmdrp.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownMedrepAdd = true;
                }else if(purpose == "edit"){
                    this.loadingDropdownMedrepEdit = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-medrep',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownMedrepAdd = res.data.data;
                        this.loadingDropdownMedrepAdd = false;
                    }else if(purpose == "edit"){
                        this.dataDropdownMedrepEdit = res.data.data;
                        this.loadingDropdownMedrepEdit = false;
                    }else if(purpose == null){
                        this.dataDropdownMedrepAdd = res.data.data;
                        this.dataDropdownMedrepEdit = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loadingAddNew = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userhp/create',
                data: {
                    "username": this.forms.username,
                    "name": this.forms.name,
                    "level_id": this.forms.level_id,
                    "id_mr": this.forms.id_mr,
                },
            })
            .then((res) => {
                this.loadingAddNew = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingAddNew = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.username = null;
            this.forms.name = null;
            this.forms.level_id = null;
            this.forms.id_mr = null;
        },
        // EDIT
		editItem(item) {
			this.item = item;
            
            // dropdown 
            this.searchDropdownLevel('', 'edit', item.level?.level_name);
            this.searchDropdownMedrep('', 'edit', item.medrep ? item.medrep.kode_mr + '-'+ item.medrep.name_mr : null);

			this.editItemDialog = true;
            this.$store.commit('setErrorEdit', {});

		},
		hideDialog() {
			this.editItemDialog = false;
			this.submitted = false;
		},
		updateItem() {
            this.loadingEdit = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userhp/update',
                data: {
                    "userid" : this.item.userid,
                    "username" : this.item.username,
                    "name" : this.item.name,
                    "level_id" : this.item.level_id,
                    "id_mr" : this.item.id_mr,
                    "password" : this.password ? this.password : null,
                    "status" : this.item.status
                },
            })
            .then((res) => {
                this.loadingEdit = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    this.item = {};
                    this.editItemDialog = false;
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingEdit = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000,});
            });
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userhp',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "level_id" : this.filters.level_id,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/master-userhp/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "level_id" : this.filters.level_id,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Master User Hp Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>